import React from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Card from '../components/Card';
import Quote from '../components/Quote';
import FeatureSlider from '../components/FeatureSlider';

import graphicMembers from '../images/graphic_members.svg';
import innowellAssess from '../images/real_time.png';
import innowellInsight from '../images/insights.png';
import innowellContent from '../images/content.png';
import innowellHabit from '../images/habit.png';

import shield from '../images/icon-shield.svg';
import microscope from '../images/icon-microscope.svg';
import user from '../images/icon-user.svg';
import graph from '../images/icon-graph.svg';

const featureSlides = [
  {
    image: innowellAssess,
    title:'Real-time Assessments',
    content:'Clinical tools and instruments, carefully designed along with mental health experts at the Brain and Mind centre give live, meaningful insights into a user’s mental health and pinpoint key focus areas.',
  },
  {
    image: innowellInsight,
    title:'Actionable Insights',
    content:'Individual privacy is protected at every level while giving your organisation deep, actionable insights into the recommended active tools to engage, support, and empower your students towards better mental health support.',
  },
  {
    image: innowellContent,
    title:'People-First Content',
    content:'A rich library of recommended articles, podcasts, activities, and more individualised for each user and designed to grow healthier self-management and understanding of personal mental health care.',
  },
  {
    image: innowellHabit,
    title:'Proactive Habit Tools',
    content:'Focus areas allow users to better self-manage mental health, and paired with habit-forming activities, help to grow healthier behaviour loops.',
  },
]

const YourMembers = () => {

  return (
    <Page title='Your Members'>
      {/* Hero */}
      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Graphic for Staff' src={graphicMembers}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Membership Means Better Mental Health.</Typography>
            <Typography type='paragraph' preset={2}>
              Research-based and people-centric, Innowell provides real-time metrics for your members’ mental health posture   and recommends personalised pathways to fitter mental health care habits.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>The BIG problem (that we're working to fix)</Typography>
        <Typography type='paragraph' preset={2}>
        1 in every 2 Australians will suffer from ill mental health at some stage in their life.
        </Typography>
        <Typography type='paragraph' preset={2}>
        To make matters worse the average wait time to see a qualified mental health professional is over 14 months. The sad news? Only half of those who need it, will get it – and when they do, it’s unlikely the first specialist they meet will be the right one for their care needs.
        </Typography>
        <Typography type='paragraph' preset={2}>
        Mental health can longer be a secondary consideration.
        </Typography>
        <Typography type='heading' preset={2}>Your Solution</Typography>

        <ul>
          <li>
            <Typography type='paragraph' preset={2}>Science-backed tools and pathways that focus on reflection, self-management, and reshaping behaviour loops.</Typography>
          </li>
          <li>
            <Typography type='paragraph' preset={2}>Growing a “mental health”-first cultures that doesn’t just improve quality-of-life for your members but work proactively to strengthen wellbeing and happiness within your organisation.</Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Proactive – not reactive – tools that support all areas of member wellbeing, through seven domains of mental health. </Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>
            Be at the forefront and lead a “mental health”-first culture, supporting your people be well, with Innowell.
            </Typography>
          </li>
        </ul>
      </Section>

      {/* Quote */}
      <Section theme='mint'>
        <Quote content='What if your leaders from every level down, lead the way forward to better, healthier mental wellbeing. Imagine if your organisation was at the forefront of that revolution.'/>
      </Section>

      {/* Icon Grid */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={graph} alt='Data Driven icon'/>
              <Typography type='heading' preset={3}>Data Driven</Typography>
              <Typography type='paragraph' preset={2}>Real-time, configurable analysis and deep analytics that give you control over meaningful, actionable reporting.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={user} alt='People Centric icon'/>
              <Typography type='heading' preset={3}>People Focused</Typography>
              <Typography type='paragraph' preset={2}>Tools backed by real science and pathways tailored to individual needs. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={shield} alt='Secure & Private icon'/>
              <Typography type='heading' preset={3}>Secure & Private</Typography>
              <Typography type='paragraph' preset={2}>We never share data without your wish.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={microscope} alt='Research Validated icon'/>
              <Typography type='heading' preset={3}>Research Validated</Typography>
              <Typography type='paragraph' preset={2}>More than 10 years of research and over a dozen research trials. </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* The Clinical Platform */}
      <Section title='The Innowell Platform'>
        <FeatureSlider slides={featureSlides}/>
      </Section>

    </Page>
  )
};

export default YourMembers;
